/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-18",
    versionChannel: "nightly",
    buildDate: "2024-09-18T00:05:43.159Z",
    commitHash: "35f1fda4b2eb363696842b4d4fc23a9ad8cf0e0f",
};
